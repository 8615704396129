import React, { FC } from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

export const EditComponent: FC = () => {
	return (
		<Edit title={'Edit Agency'}>
			<SimpleForm>
				<TextInput source="username" />
				<TextInput source="firstname" />
				<TextInput source="lastname" />
				<TextInput source="email" />
			</SimpleForm>
		</Edit>
	);
};
