import { ListComponent } from './List';
import { EditComponent } from './Edit';
import { CreateComponent } from './Create';

const components = {
	list: ListComponent,
	edit: EditComponent,
	create: CreateComponent
};

export default components;
