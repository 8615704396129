import React, { FC } from "react";
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { isAgency } from "../../utils/isAgency";

export const EditComponent: FC = () => {
  return (
    <Edit title={"Edit User"}>
      <SimpleForm>
        <TextInput source="username" />
        <TextInput source="firstname" />
        <TextInput source="lastname" />
        <TextInput source="email" />
       {!isAgency() && <ReferenceInput source="agency" reference="agencies">
        <SelectInput optionText="username" />
        </ReferenceInput>}
      </SimpleForm>
    </Edit>
  );
};
