import React, { FC } from 'react';
import { Datagrid, List, TextField } from 'react-admin';

export const ListComponent: FC = () => {
	return (
		<List title={'Agencies'}>
			<Datagrid rowClick="edit"  bulkActionButtons={false}>
				<TextField source="username" />
				<TextField source="email" />
				<TextField source="firstname" />
				<TextField source="lastname" />
			</Datagrid>
		</List>
	);
};
