import axios from "axios";

import { SIGN_IN_URL, SIGN_OUT_URL } from "../constants/api-constants";
import {
  cleanUserTokensFromLocalStorage,
  getAccessToken,
  getRefreshToken,
  getUserFromLocalStorage,
  setUserToLocalStorage,
  setUserTokensToLocalStorage,
} from "./helpers/auth";
import http from "./http-common";
import { getPermissionsByRoles } from "./permissions";

interface Props {
  username: string;
  password: string;
}

interface ErrorProps {
  status: number;
}

export const getUser = (): User | undefined => {
  return getUserFromLocalStorage();
};

const authProvider = {
  login: async ({ username, password }: Props) => {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}${SIGN_IN_URL}`,
      { username, password }
    );
    setUserTokensToLocalStorage(
      request.data.tokens.accessToken,
      request.data.tokens.refreshToken
    );
    setUserToLocalStorage(request.data.user);
    return Promise.resolve();
  },
  logout: async () => {
    await http.post(`${SIGN_OUT_URL}`, {
      refreshToken: getRefreshToken(),
    });
    cleanUserTokensFromLocalStorage();
    return Promise.resolve();
  },
  checkAuth: () => (getAccessToken() ? Promise.resolve() : Promise.reject()),
  checkError: (error: ErrorProps) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      cleanUserTokensFromLocalStorage();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getIdentity: () => {
    const user = getUserFromLocalStorage();
    const fullName = `${user?.firstname} ${user?.lastname}`;
    user.fullName = fullName;
    return Promise.resolve(user);
  },
  getPermissions: async () => {
    try {
      const user = getUser();
      if (user !== undefined) {
        if (user.isAgency) user.role = "agency";
        else user.role = "admin";
        const permissions = getPermissionsByRoles(user.role);
        return await Promise.resolve(permissions);
      } else {
        throw new Error("You are not a registered user.");
      }
    } catch (error) {
      throw new Error("You are not a registered user.");
    }
  },
};

export default authProvider;
