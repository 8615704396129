import * as constants from './constants'

const basePermissions = {
  [constants.R_USERS]: { read: true, write: true, delete: true },
  [constants.R_AGENCY]: {read: false, write: false, delete: false}
}

const permissions: Record<UserRole, ResourcePermissions> = {
  'agency': { ...basePermissions },
  'admin': {
    ...basePermissions,
    [constants.R_AGENCY]: {read: true, write: true, delete: true}
  }
}

export default permissions

export const getPermissionsByRoles = (role: UserRole): ResourcePermissions => {
  const userPermissions = permissions[role]
  return userPermissions
}

export const canAccess = (
  permissions: ResourcePermissions,
  resource: string,
  actions: Permission
): boolean => {
  if (typeof permissions === 'undefined') return false

  const resourcePermissions =
    typeof permissions[resource] !== 'undefined'
      ? permissions[resource]
      : undefined

  if (typeof resourcePermissions === 'undefined') return false

  if (
    resourcePermissions.read === true &&
    resourcePermissions.delete === true &&
    resourcePermissions.write === true
  ) {
    return true
  }

  return Object.keys(actions).every((key: string) => {
    const actionKey = key as keyof Permission
    const action = actions[actionKey]
    const resourceAction = resourcePermissions[actionKey]
    if (
      typeof action !== 'undefined' &&
      typeof resourceAction !== 'undefined'
    ) {
      return action === resourceAction
    }
    return false
  })
}
