import {
  DataProvider,
  DeleteParams,
  GetManyReferenceParams,
  UpdateManyParams,
} from "react-admin";

import http from "../auth/http-common";
import { isAgency } from "../utils/isAgency";
import queryString from "query-string";

const SERVICE_URL = isAgency()
  ? `${process.env.REACT_APP_API_URL}/agency`
  : `${process.env.REACT_APP_API_URL}/admin` ||
    "http://localhost:5000/api/admin";
const operators = ["_neq", "_eq", "_lte", "_gte"];
const nullOperators = ["__null", "__notnull"];
const SEARCH_OPERATOR = "q";

const sanitizeCode = (data: any): string | any => {
  if (typeof data === "string") {
    return data.replace(/'/g, "''");
  }
  return data;
};

const getList = async (
  path: string,
  { pagination, sort, filter }: any
): Promise<any> => {
  const offset = (pagination.page - 1) * pagination.perPage;
  let url = `${SERVICE_URL}/${path}?page=${offset}&limit=${pagination.perPage}`;
  if (sort) url += `&sort=${sort.field}&order=${sort.order}`;
  let query: any = {};

  filter = Object.entries(filter).reduce((acc: any, [key, value]) => {
    const foundOperator = operators.find((operator) => key.includes(operator));
    if (value === null) {
      if (foundOperator) {
        if (foundOperator === operators[0]) {
          const modifiedKey = key.replace(foundOperator, "");
          acc[`${modifiedKey}${nullOperators[1]}`] = "";
        } else if (foundOperator === operators[1]) {
          const modifiedKey = key.replace(foundOperator, "");
          acc[`${modifiedKey}${nullOperators[0]}`] = "";
        }
      } else {
        acc[`${key}__null`] = "";
      }
    } else if (foundOperator) {
      const modifiedKey = key.replace(
        foundOperator,
        `__${foundOperator.slice(1)}`
      );
      acc[modifiedKey] = value;
    } else if (key === SEARCH_OPERATOR) {
      query._search = sanitizeCode(value);
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});

  const queryFilter = JSON.stringify(filter).replace(/[{} ""]/g, "");

  query = {
    ...query,
    filters: queryFilter || undefined,
  };

  url += `&${queryString.stringify(query)}`;

  const response = await http.get(url, { proxy: false });

  return {
    data: response.status === 200 ? response?.data?.items : [],
    total: response?.data?.count,
  };
};

// eslint-disable-next-line consistent-return
const getOne = async (path: string, params: any): Promise<any> => {
  const response = await http.get(`${SERVICE_URL}/${path}/${params.id}`, {
    proxy: false,
  });
  if (response.status === 200) {
    if (path === "users") {
      {
        return {
          data: { ...response.data, agency: response.data?.agency?.id },
        };
      }
    }

    // eslint-disable-next-line no-underscore-dangle
    return { data: { ...(response?.data || []), id: response.data._id } };
  }
};

// eslint-disable-next-line consistent-return
const create = async (path: string, data: any): Promise<any> => {
  const response = await http.post(`${SERVICE_URL}/${path}`, data.data, {
    proxy: false,
  });
  if (response.status === 201) {
    // eslint-disable-next-line no-underscore-dangle
    return { data: { ...response?.data, id: response.data._id } };
  }
};

// eslint-disable-next-line consistent-return
const update = async (path: string, data: any): Promise<any> => {
  // eslint-disable-next-line no-underscore-dangle
  delete data.data._id;
  // eslint-disable-next-line no-underscore-dangle
  delete data.data.__v;
  delete data.data.id;
  const response = await http.put(
    `${SERVICE_URL}/${path}/${data.id}`,
    data.data,
    { proxy: false }
  );
  if (response.status === 201 || response.status === 200) {
    console.log(response.data)
    // eslint-disable-next-line no-underscore-dangle
    return { data: { ...response?.data, id: response.data._id } };
  }
};

// eslint-disable-next-line consistent-return
const deleteOne = async (path: string, params: any): Promise<any> => {
  const response = await http.delete(`${SERVICE_URL}/${path}/${params.id}`, {
    proxy: false,
  });
  if (response.status === 201) {
    // eslint-disable-next-line no-underscore-dangle
    return { data: { ...response?.data, id: response.data._id } };
  }
};

// eslint-disable-next-line consistent-return
const deleteMany = async (path: string, params: any): Promise<any> => {
  const response = await http.delete(`${SERVICE_URL}/${path}/delete`, {
    proxy: false,
    data: { ids: params.ids },
  });
  if (response.status === 200) {
    return { data: params.ids };
  }
};

// eslint-disable-next-line consistent-return
const getMany = async (path: string, params: any): Promise<any> => {
  const response = await http.get(
    `${SERVICE_URL}/${path}/${params.ids.toString()}`
  );

  if (response.status === 200) {
    return { data: response?.data || [], total: response?.data.length };
    // return { data: response?.data.map((d: any) => ({ ...d, id: d._id })) || [], total: response?.data.length };
  }
};

const getManyReference = async (
  resource: string,
  params: GetManyReferenceParams
): Promise<any> => {
  return [];
};
const updateMany = async (
  resource: string,
  params: UpdateManyParams<any>
): Promise<any> => {
  return [];
};

const deleteProvider = async (
  resource: string,
  params: DeleteParams<any>
): Promise<any> => {
  return [];
};

export const dataProvider: DataProvider = {
  getList,
  getOne,
  getMany,
  create,
  update,
  deleteOne,
  deleteMany,
  getManyReference,
  updateMany,
  delete: deleteProvider,
};
