export const cleanUserTokensFromLocalStorage = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem('user')
};

export const setUserTokensToLocalStorage = (
  accessToken: string,
  refreshToken: string
) => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
};

type UserType = {
  firstname: string;
  lastname: string;
  email: string;
  username: string;
};

export const setUserToLocalStorage = (user: UserType) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const getUserFromLocalStorage = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
};

export const getAccessToken = () => localStorage.getItem("accessToken");
export const getRefreshToken = () => localStorage.getItem("refreshToken");
