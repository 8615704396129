

// session storage value.
export const SESSION_LOGIN = "login";

// major table/resource names
export const R_USERS = "_users";
export const R_AGENCY = "agency";

export type ResourceTypes = typeof R_USERS;
