import React, { FC } from "react";
import {
  CreateButton,
  Datagrid,
  FilterButton,
  List,
  SearchInput,
  SortButton,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { isAgency } from "../../utils/isAgency";

const ListActions = () => (
  <TopToolbar>
    <SortButton fields={["id", "username"]} label="Sort by" />
    <FilterButton />
  </TopToolbar>
);

export const ListComponent: FC = () => {
  const filters = [
    <SearchInput source="q" key="q" alwaysOn />,
    <TextInput source="email" />,
    <TextInput source="username" />,
    <TextInput source="firstname" />,
    <TextInput source="lastname" />,
  ];

  return (
    <List
      {...(isAgency() ? { filters } : null)}
      {...(isAgency() ? { actions: <ListActions /> } : null)}
      title={"Users"}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="username" />
        <TextField source="email" />
        <TextField source="firstname" />
        <TextField source="lastname" />
        <TextField source="roles" />
      </Datagrid>
    </List>
  );
};
