import "./App.css";
import { Admin, Resource } from "react-admin";
import authProvider from "./auth/auth-provider";
import users from "./components/Users";
import agencies from "./components/Agencies";
import { dataProvider } from "./dataProvider";
import { useEffect, useState } from "react";
import { protectedRoutes } from "./hooks/useCanAccess";
import { R_AGENCY, R_USERS } from "./auth/constants";

function App() {
  const [permissions, setPermissions] = useState<ResourcePermissions>({});
  
  useEffect(() => {
    authProvider.getPermissions().then(setPermissions).catch(console.log);
  }, []);

  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider} disableTelemetry>
      <Resource
        name="users"
        {...protectedRoutes(permissions, R_USERS, users)}
      />
      <Resource name="agencies" {...protectedRoutes(permissions, R_AGENCY, agencies)} />
    </Admin>
  );
}

export default App;
