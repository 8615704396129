import { FC } from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

export const CreateComponent: FC = () => {
	return (
		<Create title={'Create Agency'}>
			<SimpleForm>
				<TextInput source="username" />
				<TextInput source="firstname" />
				<TextInput source="lastname" />
				<TextInput source="email" />
                <TextInput source="password" />
			</SimpleForm>
		</Create>
	);
};
